import { useStripe } from '../../utils/stripe';
import message, { Feature } from '../../utils/message';
import { defaultStyleObject } from '../../utils/styles';
import {
  BillingDetails,
  CardCvcElement,
  CardElementOptions,
  CardExpiryElement,
  CardInputPlaceholders,
  CardNumberElement,
  CardOptions,
  Elements,
  Environment,
  MountTargets,
  PaymentMethodResult,
  Stripe,
} from '../../types';

export class CreditCardElements {
  // ===========================================================================
  // Properties
  // ===========================================================================

  private stripe?: Stripe;

  private defaultMountTargets: MountTargets = {
    number: '[data-olo-pay-card-number]',
    expiry: '[data-olo-pay-card-expiry]',
    cvc: '[data-olo-pay-card-cvc]',
  };

  private defaultOptions: CardElementOptions = {
    classes: {
      base: 'olo-pay',
      complete: 'olo-pay--complete',
      empty: 'olo-pay--empty',
      focus: 'olo-pay--focus',
      invalid: 'olo-pay--invalid',
      webkitAutofill: 'olo-pay--webkit-autofill',
    },
  };

  private defaultPlaceholders: CardInputPlaceholders = {
    number: 'Card number',
    expiry: 'Card expiry',
    cvc: 'Card CVC',
  };

  cardNumber?: CardNumberElement;
  cardExpiry?: CardExpiryElement;
  cardCvc?: CardCvcElement;
  elements?: Elements[];

  // ===========================================================================
  // Constructor
  // ===========================================================================

  constructor(private environment: Environment = 'development') {}

  // ===========================================================================
  // Methods
  // ===========================================================================

  /**
   * Appends a credit card field to a passed element or selector of a DOM element.
   *
   * Defaults:
   * - Credit card number: `data-olo-pay-card-number`
   * - Credit card expiration date: `data-olo-pay-card-expiry`
   * - Credit card CVC: `data-olo-pay-card-cvc`
   *
   * Note: all three fields are required for a valid credit card submission.
   *
   * @param cardOptions Options object containing information for:
   * `cardElementOptions`, `elementsOptions`, `mountTargets`, and `placeholders`.
   */
  async create(cardOptions?: CardOptions): Promise<void> {
    this.stripe = await useStripe(Feature.CreditCardElements, this.environment);
    if (!this.stripe) return;

    const elements = this.stripe.elements(cardOptions?.elementsOptions || {});

    const options = {
      ...this.defaultOptions,
      ...cardOptions?.cardElementOptions,
    };

    const placeholders = cardOptions?.placeholders || this.defaultPlaceholders;

    // Create the elements
    const cardNumber = elements.create('cardNumber', {
      ...options,
      placeholder: placeholders.number,
    });

    const cardExpiry = elements.create('cardExpiry', {
      ...options,
      placeholder: placeholders.expiry,
    });

    const cardCvc = elements.create('cardCvc', {
      ...options,
      placeholder: placeholders.cvc,
    });

    // Mount the elements to their DOM targets
    const targets = cardOptions?.mountTargets || this.defaultMountTargets;

    try {
      cardNumber.mount(targets.number);
      cardExpiry.mount(targets.expiry);
      cardCvc.mount(targets.cvc);
    } catch (e) {
      message.error(
        `${Feature.CreditCardElements}:
        Unable to mount Olo Pay elements to their DOM targets
        Please ensure valid selectors or elements are passed to \`create\``
      );
      return;
    }

    // Store references to mounted elements
    this.cardNumber = elements.getElement('cardNumber') || undefined;
    this.cardExpiry = elements.getElement('cardExpiry') || undefined;
    this.cardCvc = elements.getElement('cardCvc') || undefined;

    if (!this.cardNumber) return message.error('Number input not mounted properly');
    if (!this.cardExpiry) return message.error('Expiry input not mounted properly');
    if (!this.cardCvc) return message.error('CVC input not mounted properly');

    this.elements = [this.cardNumber, this.cardExpiry, this.cardCvc] || [];
  }

  /**
   * Creates a payment method using card details on the form and
   * billing details (if passed).
   *
   * @param billingDetails Address, email, name, and phone.
   */
  async createPaymentMethod(
    billingDetails?: BillingDetails
  ): Promise<PaymentMethodResult | undefined> {
    if (!this.stripe || !this.cardNumber) {
      message.error(
        `Failed to create a payment method due to missing: ${
          this.stripe ? 'cardNumber element' : 'Olo Pay connection. Call `create` first'
        }`
      );
      return;
    }

    const paymentMethod = await this.stripe.createPaymentMethod({
      type: 'card',
      card: this.cardNumber,
      billing_details: billingDetails,
    });

    return paymentMethod;
  }

  /**
   * Applies a style object to the credit card elements.
   *
   * @param style Style object.
   */
  applyStyles(style = defaultStyleObject): void {
    this.cardNumber?.update({ style });
    this.cardExpiry?.update({ style });
    this.cardCvc?.update({ style });
  }

  /**
   * Clear all credit card field values.
   */
  clear(): void {
    this.cardNumber?.clear();
    this.cardExpiry?.clear();
    this.cardCvc?.clear();
  }

  /**
   * Removes credit card elements from the DOM and destroys them.
   * A destroyed element can not be re-activated or re-mounted to the DOM.
   */
  destroy(): void {
    this.cardNumber?.destroy();
    this.cardExpiry?.destroy();
    this.cardCvc?.destroy();
    this.elements = undefined;
  }

  /**
   * Mounts credit card elements to the DOM.
   * Call `unmount` to remove them from the DOM.
   *
   * @param mountTargets Object containing selector or element targets.
   */
  mount(mountTargets: MountTargets = this.defaultMountTargets): void {
    this.cardNumber?.mount(mountTargets.number);
    this.cardExpiry?.mount(mountTargets.expiry);
    this.cardCvc?.mount(mountTargets.cvc);
  }

  /**
   * Unmounts credit card elements from the DOM.
   * Call `mount` to re-attach them to the DOM.
   */
  unmount(): void {
    this.cardNumber?.unmount();
    this.cardExpiry?.unmount();
    this.cardCvc?.unmount();
  }

  /**
   * Updates the options the credit card elements were initialized with.
   * Updates are merged into the existing configuration.
   *
   * @param newOptions
   */
  update(newOptions = this.defaultOptions): void {
    this.cardNumber?.update(newOptions);
    this.cardExpiry?.update(newOptions);
    this.cardCvc?.update(newOptions);
  }
}
