import { PaymentRequestOptions, PaymentRequestUpdateOptions } from '@stripe/stripe-js';
import {
  PaymentRequestPaymentMethodEvent,
  PaymentMethod,
  PaymentRequestButtonElement,
  PaymentRequest,
  DigitalWalletsOptions,
  Environment,
  CanMakePaymentResult,
  SupportedCountries,
  Stripe,
} from '../../types';

import find from '../../utils/find';
import message, { Feature } from '../../utils/message';
import { useStripe } from '../../utils/stripe';
import { TEST_PAYMENT_METHOD } from './data/test-payment-method';

export class DigitalWallets {
  // ===========================================================================
  // Properties
  // ===========================================================================

  private stripe?: Stripe;
  private digitalWalletsOptions?: DigitalWalletsOptions;
  private paymentEvent?: PaymentRequestPaymentMethodEvent;
  private paymentRequest?: PaymentRequest;

  private defaultPaymentRequestOptions: Omit<PaymentRequestOptions, 'country'> = {
    currency: 'usd',
    total: {
      label: 'test',
      amount: 0,
    },
    requestPayerName: true,
  };
  // Indicates whether a digital wallets payment can be made
  canMakePayment: CanMakePaymentResult | null = null;
  // Built-in payment button (Apple Pay or Google Pay)
  paymentButton?: PaymentRequestButtonElement;

  // ===========================================================================
  // Constructor
  // ===========================================================================

  constructor(private environment: Environment = 'development') {}

  // ===========================================================================
  // Methods
  // ===========================================================================

  /**
   * Initializes a digital wallet's payment request so that a `DigitalWallets` button can be created.
   *
   * @param options Digital wallets options containing style and payment request options.
   * @example
   * HTML
   * <div data-olo-pay-payment-button></div>
   *
   * @example
   * JS
   * const digitalWallets = new DigitalWallets();
   * await digitalWallets.initialize(options);
   *
   * // Will return an object of { applePay: false, googlePay: false }
   * const canMakePayment = digitalWallets.canMakePayment;
   */
  async initialize(options: DigitalWalletsOptions) {
    if (!this.stripe) {
      this.stripe = await useStripe(Feature.DigitalWallets, this.environment);
    }
    if (!this.stripe) return;

    if (!options) {
      message.error('No digital wallets options provided');
      return;
    }

    this.digitalWalletsOptions = options;

    // If a default paymentRequest already exists, update it
    // Also, only update the payment request options if the payment sheet is not showing (in the event of a re-render or timing issue)
    if (this.paymentRequest && !this.paymentRequest.isShowing()) {
      const { currency, total, displayItems, shippingOptions } = this.digitalWalletsOptions.options;

      this.updatePaymentDetails({
        currency,
        total,
        displayItems,
        shippingOptions,
      });

      this.canMakePayment = await this.paymentRequest.canMakePayment();
      return;
    }

    // Initialize the payment request with provided options
    const paymentRequest = this.stripe.paymentRequest(this.digitalWalletsOptions.options);

    this.canMakePayment = await paymentRequest.canMakePayment();
    this.paymentRequest = paymentRequest;
  }

  /**
   * Appends a `Stripe Elements` Payment Request Button to an element marked with a matching `data-olo-pay-payment-button` attribute.
   * @param callback Function to execute after the payment is submitted in the digital wallets payment sheet.
   *
   * @example
   * HTML
   * <div data-olo-pay-payment-button></div>
   *
   * @example
   * JS
   * const digitalWallets = new DigitalWallets();
   * await digitalWallets.initialize(options);
   * digitalWallets.mountButton(callback);
   */
  mountButton(callback: (paymentMethod: PaymentMethod) => void, target?: string | HTMLElement) {
    // Grab the intended Payment Request Button element.
    // If a button doesn't exist, user may be implementing their own custom button.
    const buttonSlot = find(target || '[data-olo-pay-payment-button]');

    if (!buttonSlot) return;

    if (!this.digitalWalletsOptions || !this.paymentRequest || !this.stripe) {
      message.error('Digital Wallets not initialized properly.');
      return;
    }

    const { classes, style } = this.digitalWalletsOptions;
    const elements = this.stripe.elements();
    const paymentRequest = this.paymentRequest;

    // Intercept the 'paymentmethod' request. Return the payment method object to client.
    paymentRequest.on('paymentmethod', (ev: PaymentRequestPaymentMethodEvent) => {
      this.paymentEvent = ev;
      callback(ev.paymentMethod);
    });

    const button = elements.create('paymentRequestButton', {
      paymentRequest,
      // Add optional classes object if it exists
      ...(classes ? classes : {}),
      // Add optional style object if it exists
      ...(style ? { style: { paymentRequestButton: { ...style } } } : {}),
    });

    // Determine if Apple Pay or Google Pay are applicable.
    // If either Apple Pay or Google Pay is available, render the button.
    // Otherwise, hide the slot reserved for the payment request button
    if (buttonSlot && this.canMakePayment) {
      button.mount('[data-olo-pay-payment-button]');
    } else {
      buttonSlot.style.display = 'none';
    }

    // In test mode, intercept the button's click event and return a test payment method
    if (this.environment === 'test') {
      message.log('Digital Wallets is running in test mode.');

      button.on('click', (event) => {
        event.preventDefault();

        window.PaymentRequest = this.paymentRequest;
        window.PaymentRequest.emit('paymentmethod', TEST_PAYMENT_METHOD);
      });
    }

    this.paymentButton = button;
  }

  /**
   * Initiates a payment by showing the Google Pay or Apple Pay payment sheet.
   * This can be triggered when using a custom payment button,
   * or in scenarios where preventing the default 'click' event behavior is necessary
   * (i.e. proper form validation prior to initiating the payment).
   *
   * @example
   * const digitalWallets = new DigitalWallets();
   * await digitalWallets.initialize(options);
   *
   * digitalWallets.paymentButton.on('click', (event) => {
   *  event.preventDefault();
   *
   *  // Launch payment sheet if form is valid
   *  if (formIsValid) {
   *    dw.initiatePayment();
   *  }
   * });
   */
  initiatePayment() {
    if (this.canMakePayment) {
      this.paymentRequest?.show();
    }
  }

  /**
   * Determines which digital wallets are available in the current browser context.
   *
   * @example
   * const canRenderButton = await digitalWallets.canRenderButton();
   * if (canRenderButton && (canRenderButton.applePay || canRenderButton.googlePay)) {
   *  // Do something
   * }
   */
  async canRenderButton(country: SupportedCountries = 'US'): Promise<CanMakePaymentResult | null> {
    this.stripe = await useStripe(Feature.DigitalWallets, this.environment);
    if (!this.stripe) return null;

    this.paymentRequest = this.stripe?.paymentRequest({
      ...this.defaultPaymentRequestOptions,
      country,
    });

    this.canMakePayment = await this.paymentRequest.canMakePayment();

    return this.canMakePayment;
  }

  /**
   * Updates payment details to be reflected on the payment sheet
   */
  updatePaymentDetails(options: PaymentRequestUpdateOptions) {
    if (!this.paymentRequest) return;

    this.paymentRequest.update(options);
  }

  /**
   * Completes the pending payment event to close the active payment sheet
   */
  completePaymentEvent() {
    this.paymentEvent?.complete('success');
  }

  /**
   * Fails the pending payment event
   */
  failPaymentEvent() {
    this.paymentEvent?.complete('fail');
  }

  /**
   * Removes the element from the DOM and destroys it.
   * A destroyed element can not be re-activated or re-mounted to the DOM.
   */
  destroy() {
    this.paymentButton?.destroy();
  }

  /**
   * Unmounts the element from the DOM. Call element.mount to re-attach it to the DOM.
   */
  unmount() {
    this.paymentButton?.unmount();
  }
}

// Used in test mode in order to emit a 'paymentmethod' event
declare global {
  interface Window {
    PaymentRequest: any;
  }
}
