import { useStripe } from '../../utils/stripe';
import message, { Feature } from '../../utils/message';
import { defaultStyleObject } from '../../utils/styles';
import {
  BillingDetails,
  CardElementOptions,
  CardSingleLineElement,
  SingleLineCardElementOptions,
  Environment,
  PaymentMethodResult,
  Stripe,
} from '../../types';

export class SingleLineCardElement {
  // ===========================================================================
  // Properties
  // ===========================================================================

  private stripe?: Stripe;

  private defaultMountTarget: string | HTMLElement = '[data-olo-pay-card-single-line]';

  // TODO: Move this
  private defaultOptions: CardElementOptions = {
    classes: {
      base: 'olo-pay',
      complete: 'olo-pay--complete',
      empty: 'olo-pay--empty',
      focus: 'olo-pay--focus',
      invalid: 'olo-pay--invalid',
      webkitAutofill: 'olo-pay--webkit-autofill',
    },
  };

  element?: CardSingleLineElement;

  // ===========================================================================
  // Constructor
  // ===========================================================================

  constructor(private environment: Environment = 'development') {}

  // ===========================================================================
  // Methods
  // ===========================================================================

  /**
   * Appends a single-line credit card field to a passed element or selector of a DOM element.
   *
   * If no value for `mountTarget` is passed in, the default mount target is `data-olo-pay-card-single-line`.
   *
   * @param cardOptions Options object containing information for:
   * `cardElementOptions`, `elementsOptions`, `mountTarget`.
   */
  async create(cardOptions?: SingleLineCardElementOptions): Promise<void> {
    this.stripe = await useStripe(Feature.SingleLineCardElement, this.environment);
    if (!this.stripe) return;

    const elements = this.stripe.elements(cardOptions?.elementsOptions || {});

    const options = {
      ...this.defaultOptions,
      ...cardOptions?.cardElementOptions,
    };

    const card = elements.create('card', {
      ...options,
    });

    // Mount the element to its DOM target
    const target = cardOptions?.mountTarget || this.defaultMountTarget;

    try {
      card.mount(target);
    } catch (e) {
      message.error(
        `${Feature.SingleLineCardElement}:
          Unable to mount Olo Pay single-line card element to its DOM target
          Please ensure valid selectors or elements are passed to \`create\``
      );
      return;
    }

    // Store reference to mounted element
    this.element = elements.getElement('card') || undefined;

    if (!this.element) return message.error('Single-line card element not mounted properly');

    return;
  }

  /**
   * Creates a payment method using card details on the form and
   * billing details (if passed).
   *
   * @param billingDetails Address, email, name, and phone.
   */
  async createPaymentMethod(
    billingDetails?: BillingDetails
  ): Promise<PaymentMethodResult | undefined> {
    if (!this.stripe || !this.element) {
      message.error(
        `Failed to create a payment method due to missing: ${
          this.stripe ? 'singleLineCard element' : 'Olo Pay connection. Call `create` first'
        }`
      );
      return;
    }

    const paymentMethod = await this.stripe.createPaymentMethod({
      type: 'card',
      card: this.element,
      billing_details: billingDetails,
    });

    return paymentMethod;
  }

  /**
   * Applies a style object to the element.
   *
   * @param style Style object.
   */
  applyStyles(style = defaultStyleObject): void {
    this.element?.update({ style });
  }

  /**
   * Clear all credit card field values.
   */
  clear(): void {
    this.element?.clear();
  }

  /**
   * Removes the element from the DOM and destroys it.
   * A destroyed element can not be re-activated or re-mounted to the DOM.
   */
  destroy(): void {
    this.element?.destroy();
    this.element = undefined;
  }

  /**
   * Mounts the element to the DOM.
   * Call `unmount` to remove it from the DOM.
   *
   * @param mountTarget Object containing selector or element target.
   */
  mount(mountTarget: string | HTMLElement = this.defaultMountTarget): void {
    this.element?.mount(mountTarget);
  }

  /**
   * Unmounts the element from the DOM.
   * Call `mount` to re-attach it to the DOM.
   */
  unmount(): void {
    this.element?.unmount();
  }

  /**
   * Updates the options that the element was initialized with.
   * Updates are merged into the existing configuration.
   *
   * @param newOptions
   */
  update(newOptions = this.defaultOptions): void {
    this.element?.update(newOptions);
  }
}
