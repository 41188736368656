import { Stripe, Environment } from '../types';
import { onWindow } from './helpers';
import message, { Feature } from '../utils/message';

// TODO: This export is not resolving properly, likely due
// to an issue with how TSDX, Rollup, and Stripe get along
import * as pure from '@stripe/stripe-js/pure';
/**
 * Load Stripe JS only when the `loadStripe` function is called.
 * We want this by default—especially for Serve—which has many
 * brands not using Olo Pay. Using the common `loadStripe` import
 * will inject Stripe JS on app start and continually send data.
 */
const loadWithoutSideEffect = pure.loadStripe;

enum URL {
  development = 'https://static.olocdn.net/web-client/olo-pay/keys/dev.json',
  production = 'https://static.olocdn.net/web-client/olo-pay/keys/prod.json',
}

/**
 * Sets up fraud signals and events by injecting a script into
 * the document `<head>` that loads supporting code. This will
 * also happen on instantiation of an Olo Pay feature, but can
 * be called early to improve fraud detection.
 */
async function initializeOloPay(): Promise<void> {
  if (!window.Stripe) {
    try {
      await import('@stripe/stripe-js');
    } catch (e) {}
  }
}

/**
 * Loads Stripe instance and intercepts errors for a generic response.
 */
async function setupStripe(feature: Feature, key: string): Promise<Stripe | void> {
  let stripe: Stripe | null = null;

  try {
    if (onWindow()) {
      // Likely UMD
      stripe = window.Stripe?.(key) ?? null;
    }

    if (stripe === null) {
      // Likely ESM
      stripe = await loadWithoutSideEffect(key);
    }

    if (stripe === null) {
      throw new Error();
    }

    return stripe;
  } catch (e) {
    message.error(`${feature}: Please ensure you are passing a valid key`);
  }
}

/**
 * Fetches a publishable key from Olo CDN (dev.json or prod.json).
 */
async function fetchKey(url: URL): Promise<string> {
  try {
    const response = await fetch(url);
    const json = await response.json();
    return json.key.trim() || '';
  } catch (e) {
    return '';
  }
}

/**
 * Sets up Stripe in development or production mode.
 */
async function useStripe(feature: Feature, env: Environment): Promise<Stripe | undefined> {
  const url = /\bproduction\b/i.test(env) ? URL.production : URL.development;
  const key = await fetchKey(url);
  const stripe = await setupStripe(feature, key);

  if (!stripe) {
    message.error(`${feature}: Failed to access Olo Pay`);
    return;
  }
  return stripe;
}

export { initializeOloPay, useStripe };
